import React from "react";
import { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Pricing from "../components/Pricing";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";

import {
  Anchor,
  Box,
  Heading,
  Image,
  Meter,
  Paragraph,
  ResponsiveContext,
  Stack,
  Text,
} from "grommet";

import chantriesLogo from "../img/chantries-logo.png";

// eslint-disable-next-line
export const ProductPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials,
  fullImage,
  pricing,
}) => {
  const heroImage = getImage(image) || image;
  const fullWidthImage = getImage(fullImage) || fullImage;
  const size = useContext(ResponsiveContext);
  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <Box
        justify="center"
        align="center"
        fill="horizontal"
        direction="column"
        pad={size === "small" ? { horizontal: "20px" } : { horizontal: "80px" }}
      >
        <Box
          margin={{ vertical: "large" }}
          align="center"
          fill="horizontal"
          pad={{ bottom: "large" }}
        >
          <Text textAlign="center">
            The PTA is proudly supported by the following organisations:
          </Text>
          <Box
            direction={size === "small" ? "column" : "row"}
            gap={size === "small" ? "small" : "xlarge"}
            margin={{ top: "medium" }}
            pad={
              size === "small"
                ? { vertical: "medium", horizontal: "small" }
                : { vertical: "medium", horizontal: "xlarge" }
            }
            border="bottom"
            fill="horizontal"
          >
            <Box width={size === "small" ? "100%" : "200px"}>
              <Anchor href="https://chantriesandpewleys.com/" target="_blank">
                <Image
                  width={size === "small" ? "100%" : "200px"}
                  src={chantriesLogo}
                />
              </Anchor>
            </Box>
            <Box width="100%">
              <Paragraph textAlign="left" fill>
                Chantries & Pewleys Independent Estate Agents specialise in the
                sales and lettings market within the Guildford area. Highly
                respected market leaders in their field they pride themselves on
                their dedication and the levels of service provided to their
                clients coupled with an unrivalled local knowledge and wealth of
                experience.
              </Paragraph>
              <Paragraph textAlign="left" fill>
                Chantries & Pewleys recognise that moving can be stressful,
                which is why they specialise in helping clients navigate the
                process through their blend of services, every step of the way.
              </Paragraph>
              <Paragraph textAlign="left" fill>
                Coverage is provided through their network of 4 offices in
                Shalford, Guildford, Merrow & Cranleigh.
              </Paragraph>
              <Paragraph textAlign="left" fill>
                For a free property valuation or assistance RE a property sale
                or purchase do contact Richard Prynne at the Shalford office -
                01483 304 344 - or{" "}
                <Anchor href="mailto:rprynne@chantriesandpewleys.com">
                  rprynne@chantriesandpewleys.com
                </Anchor>
                .
              </Paragraph>
              <Heading level={3}>
                Offer for Shalford Infant School Parents:
              </Heading>
              <Paragraph textAlign="left" fill>
                For any parents at the school who engage Chantries & Pewleys to
                assist them with a successful property sale, Chantries & Pewleys
                will donate £500 of their fees to the Shalford Infant School
                PTA.
              </Paragraph>
              <Paragraph textAlign="left" fill>
                Simply mention Shalford Infants when you contact the Shalford
                office using the details above.
              </Paragraph>
            </Box>
          </Box>
          <Box margin={{ top: "large" }}>
            <Text size="small" weight="bold" color="#999" textAlign="center">
              Interested in being a sponsor?{" "}
              <Anchor
                href="mailto:shalfordpta@gmail.com"
                style={{ color: "brand" }}
              >
                Contact us
              </Anchor>
            </Text>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(quality: 72, layout: FULL_WIDTH)
              }
            }
          }
        }
        testimonials {
          author
          quote
        }

        full_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`;
